import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const TCUsers = () => (
  <Layout headerRight="restaurants">
    <SEO
      pageTitle="Condizioni d'uso della piattaforma - Mangiami"
      pageDescription="Questo documento disciplina la registrazione e l'uso della nostra piattaforma digitale."
      pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
      pageUrl="/terms-and-conditions"
    />
    <div
      style={{
        paddingBottom: 20,
        paddingTop: 40,
        maxWidth: 900,
        margin: '0 auto',
      }}
    >
      <h2>CONDIZIONI D’USO DELLA PIATTAFORMA</h2>
      <p>
        <strong>INTRODUZIONE</strong>
      </p>
      <p>
        Il presente documento disciplina la registrazione e l&rsquo;uso della piattaforma digitale
        &ldquo;<em>mangiami</em>&rdquo; (la &ldquo;Piattaforma&rdquo;), composta dal sito&nbsp;
        <a href="https://mangiami.ch/">mangiami.ch</a> (il &ldquo;Sito&rdquo;) e delle risorse ad
        esso collegate, incluse le pagine e i profili sui social media (in particolare: Facebook e
        Instagram), nonch&eacute; l&rsquo;App per dispositivi mobili &ldquo;<em>mangiami</em>
        &rdquo; (l&rsquo;&ldquo;App&rdquo;), disponibile gratuitamente e senza registrazione presso
        gli store online.
      </p>
      <p>
        I <u>paragrafi A e B</u> specificano la titolarit&agrave; della Piattaforma, le coordinate
        per le comunicazioni e descrivono il meccanismo di accettazione e revisione del presente
        documento. Le condizioni per poter accedere ed utilizzare la Piattaforma e le sue
        funzionalit&agrave; sono descritte nel <u>paragrafo C</u>. Infine, il <u>paragrafo D</u>{' '}
        regola il diritto materiale applicabile alla relazione giuridica tra le parti e stabilisce
        il foro competente in caso di vertenza collegata a tale relazione.
      </p>
      <p>
        La politica in materia di trattamento dei dati personali e dei cookies relativa alla
        Piattaforma, con conseguente informativa all&rsquo;utente, &egrave; descritta in un
        documento separato, accessibile a questo [link](
        <a href="https://mangiami.ch/privacy-policy">https://mangiami.ch/privacy-policy</a>). Lo
        stesso s&rsquo;intende qui integralmente riprodotto e integrato.
      </p>
      <p>
        Per &ldquo;utente&rdquo; s&rsquo;intende qualsiasi persona, fisica o giuridica, che utilizza
        la Piattaforma. Per &ldquo;ristoratore&rdquo; s&rsquo;intende qualsiasi persona, fisica o
        giuridica, che risulta affiliata alla Piattaforma in quanto offerente di servizi di
        ristorazione.
      </p>
      <p>&nbsp;</p>
      <li>
        <strong>TITOLARE DELLA PIATTAFORMA E COMUNICAZIONI</strong>
      </li>
      <p>
        La Piattaforma &egrave; di propriet&agrave; di Hextech SA, Sorengo (CH)
        (&ldquo;Hextech&rdquo; o &ldquo;Titolare&rdquo;).
      </p>
      <p>
        Tutte le comunicazioni dovranno avvenire per iscritto e s&rsquo;intenderanno validamente ed
        efficacemente eseguite alla ricezione delle stesse, se effettuate per posta ordinaria,
        rispettivamente al momento dell&rsquo;invio della conferma di lettura, se effettuate per
        posta elettronica.
      </p>
      <p>
        <u>Contatti</u>:
      </p>
      <ul>
        <li>Hextech SA, Via Paradiso 24, 6924 Sorengo (CH)</li>
        <li>Tel. 076 534 55 47 (dalla Svizzera)</li>
        <li>
          E-mail:&nbsp;<a href="mailto:support@mangiami.ch">support@mangiami.ch</a>&nbsp;
        </li>
      </ul>
      <p>&nbsp;</p>
      <li>
        <strong>ACCETTAZIONE E MODIFICA DEI TERMINI E DELLE CONDIZIONI</strong>
      </li>
      <p>
        Mediante l&rsquo;uso della Piattaforma, l&rsquo;utente accetta i termini e le condizioni in
        vigore al momento dell&rsquo;accesso. La versione vigente pu&ograve; essere visualizzata
        cliccando sull&rsquo;apposito link posto in calce al Sito. &Egrave; responsabilit&agrave;
        dell&rsquo;utente verificare con cura lo stato dei termini e delle condizioni prima di
        accedere alla Piattaforma, essendo riservato il diritto del Titolare di aggiornare il
        presente documento in qualsiasi momento e a propria discrezione, segnatamente in funzione
        dell&rsquo;evoluzione del diritto applicabile, delle funzionalit&agrave;, nonch&eacute; dei
        servizi e dei prodotti messi a disposizione dell&rsquo;utente.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <li>
        <strong>CONDIZIONI D&rsquo;USO DELLA PIATTAFORMA</strong>
      </li>
      <p>
        <strong>
          <u>Oggetto</u>
        </strong>
      </p>
      <p>
        La Piattaforma consente agli utilizzatori di consultare informazioni, immagini e prezzi
        relativi alle attivit&agrave; di ristorazione presenti sulla Piattaforma, di pubblicare
        contenuti (in particolare, recensioni sui ristoranti), di effettuare una riservazione o
        un&rsquo;ordinazione direttamente dall&rsquo;App, come pure di ricevere, in collaborazione
        con i ristoratori, proposte personalizzate e offerte vantaggiose inerenti servizi di
        ristorazione.
      </p>
      <p>
        I contenuti presenti sulla Piattaforma, in particolare le fotografie dei cibi e le schede
        dei ristoranti, sono pubblicati{' '}
        <strong>
          <u>a titolo meramente informativo e senza garanzia</u>
        </strong>
        , per cui alcuni cibi, bevande e/o servizi (incluso il prezzo) potrebbero non essere sempre
        aggiornati e/o disponibili.&nbsp;
      </p>
      <p>
        <u>
          Si raccomanda pertanto in occasione del contatto con il ristorante per la riservazione del
          servizio di chiedere{' '}
          <strong>conferma di tutti gli elementi rilevanti per la scelta dell&rsquo;utente</strong>{' '}
          (in particolare: disponibilit&agrave; e specificit&agrave; del piatto, prezzo, tempi di
          servizio o consegna, ingredienti e allergeni)
        </u>
        .
      </p>
      <p>
        <strong>
          <u>Funzione di Hextech SA quale mero fornitore di servizi di telecomunicazione</u>
        </strong>
      </p>
      <p>
        La Piattaforma agisce da <u>mero fornitore di servizi di telecomunicazione</u>, per cui:
      </p>
      <ul>
        <li>non fornisce servizi di ristorazione all&rsquo;utente;</li>
        <li>non fornisce servizi di consegna a domicilio;</li>
        <li>
          non verifica la descrizione, la qualit&agrave;, la congruit&agrave; e la
          disponibilit&agrave; dei servizi offerti dai ristoratori;
        </li>
        <li>
          non verifica la correttezza e completezza degli ingredienti presenti nella descrizione dei
          piatti e nelle bevande, n&eacute; la reale composizione dei piatti e delle bevande forniti
          dai ristoratori;
        </li>
        <li>
          non verifica la correttezza, completezza, affidabilit&agrave;, disponibilit&agrave; e
          legalit&agrave; delle informazioni e delle immagini pubblicate sulla Piattaforma (in
          particolare, tramite la sezione &ldquo;esplora&rdquo;, i profili dei ristoranti e le
          recensioni degli utenti);
        </li>
        <li>
          non effettua alcun accertamento in sede di affiliazione alla Piattaforma oppure
          successivamente, sui ristoratori e sui loro esercizi, come pure sui servizi di consegna a
          domicilio, sulle modalit&agrave; di consegna e di conservazione dei cibi e delle bevande
          durante il trasporto.
        </li>
      </ul>
      <p>
        Hextech SA non ha alcun diritto di rappresentare i ristoratori, n&eacute; il servizio
        indipendente di consegna a domicilio nell&rsquo;ambito del rapporto con gli utenti, per cui
        &egrave; esclusa la qualifica di rappresentante, mandataria, agente, distributrice e simili.
      </p>
      <p>
        <strong>
          <u>
            Il contratto avente per oggetto la prestazione di servizi nell&rsquo;ambito della
            ristorazione (inclusa la fase di negoziazione / pre-contrattuale), nonch&eacute; di
            consegna a domicilio riguarda esclusivamente e direttamente l&rsquo;utente e il
            ristoratore, rispettivamente il fornitore di servizio di consegna a domicilio
            interessato
          </u>
        </strong>
        . Il Titolare, quale parte estranea, non assume alcun ruolo, n&eacute; responsabilit&agrave;
        in relazione al corretto adempimento di tali contratti.
      </p>
      <p>
        <strong>
          <u>Politica in materia di collegamenti ipertestuali</u>
        </strong>
      </p>
      <p>
        Il Titolare autorizza, riservando il diritto di revoca in ogni tempo, i collegamenti
        ipertestuali diretti alla home page del Sito oppure ai profili social, a condizione che tali
        collegamenti non siano in alcun modo lesivi dell&rsquo;immagine della Piattaforma, del
        Titolare e dei suoi organi, dirigenti e dipendenti.&nbsp;
      </p>
      <p>
        <u>
          I collegamenti ipertestuali verso le pagine interne del Sito, lo scaricamento e
          l&rsquo;incorporazione, parziale o completa, dei contenuti della Piattaforma in altri siti
          o risorse online e/o offline (incluso il motore di ricerca interno) sono vietati&nbsp;
        </u>
        <u>
          e saranno perseguiti civilmente e penalmente, salvo licenza scritta rilasciata dal
          Titolare.
        </u>
      </p>
      <p>
        <strong>
          <u>Contenuti della Piattaforma generati o riferiti al Titolare</u>
        </strong>
      </p>
      <p>
        <strong>
          <u>a) Propriet&agrave; intellettuale</u>
        </strong>
      </p>
      <p>
        Il Titolare detiene la piena ed esclusiva propriet&agrave; intellettuale sulle singole
        componenti della Piattaforma, la sua grafica, i suoi database e i suoi contenuti, come pure
        su segni e designazioni protetti per legge, nonch&eacute; marchi registrati (qualora
        indicato) e non. Qualsivoglia utilizzazione da parte di terzi non supportata da una licenza
        d&rsquo;uso legale o contrattuale, in quest&rsquo;ultimo caso nella forma scritta, &egrave;
        illecita e perseguibile civilmente e penalmente.
      </p>
      <p>
        <strong>
          <u>b) Contenuti derivanti da social media e altre fonti pubbliche</u>
        </strong>
        &nbsp;
      </p>
      <p>
        Il Titolare pubblica anche immagini tratte da fonti pubbliche, in particolare immagini che
        ritraggono cibi, piatti e bevande, le quali in genere non sono protette dal diritto
        d&rsquo;autore in quanto prive di profilo creativo o artistico.
      </p>
      <p>
        Qualora l&rsquo;autore delle fotografie ritenesse che le stesse siano protette dal diritto
        d&rsquo;autore
        svizzero&nbsp;[LDA](&nbsp;https://www.admin.ch/opc/it/classified-compilation/19920251/index.html),
        il Titolare &egrave; a disposizione per valutare la questione. Si prega di scrivere
        all&rsquo;indirizzo&nbsp;<a href="mailto:support@mangiami.ch">support@mangiami.ch</a>{' '}
        fornendo il link della fotografia, i propri dati personali e la prova della legittimazione
        quale autore o titolare dei vantati diritti d&rsquo;autore.
      </p>
      <p>
        In caso di protezione, a propria scelta, l&rsquo;autore potr&agrave; richiedere
        l&rsquo;inserimento dell&rsquo;icona del social media e il collegamento al proprio profilo
        oppure la menzione della propria qualit&agrave; di autore oppure ancora la cancellazione
        della fotografia dalla Piattaforma.
      </p>
      <p>
        <strong>
          <u>Diritti sui contenuti generati dagli utenti e dai ristoratori</u>
        </strong>
      </p>
      <p>
        <strong>
          <u>a) Rinuncia o licenza; riserva dei diritti</u>
        </strong>
      </p>
      <p>
        Nel limite di quanto legalmente ammissibile, con la diffusione dei contenuti tramite la
        Piattaforma, ad esempio sotto forma di recensioni, documenti, messaggi, immagini, fotografie
        ecc., l&rsquo;utente rinuncia a tutti i diritti su tali contenuti, in particolare attinenti
        alla propriet&agrave; intellettuale, rispettivamente all&rsquo;esercizio di tali diritti, di
        modo che i contenuti pubblicati siano liberamente fruibili da tutti gli utenti della
        Piattaforma. Ove la suddetta rinuncia non sia ammissibile, l&rsquo;utente concede al
        Titolare e agli altri utenti (registrati e non) della Piattaforma una licenza generale,
        gratuita, non esclusiva, irrevocabile, di durata indeterminata e con effetto globale, di
        utilizzare, condividere e conservare, per scopi non commerciali, i contenuti diffusi tramite
        la Piattaforma.&nbsp;
      </p>
      <p>
        Per contro, i diritti dei ristoratori sui contenuti di loro pertinenza oggetto di diffusione
        tramite la Piattaforma (in particolare, immagini, loghi, fotografie e testi) sono pienamente
        riservati.
      </p>
      <p>
        <strong>
          <u>
            b) Cancellazione di contenuti generati dagli utenti e dai ristoratori in caso di abuso
          </u>
        </strong>
      </p>
      <p>
        Il Titolare s&rsquo;impegna a cancellare prontamente i contenuti generati dagli utenti e/o
        dai ristoratori in caso di violazione delle presenti condizioni d&rsquo;uso. La richiesta di
        cancellazione deve provenire dal titolare del diritto invocato, nonch&eacute; essere
        precisa, giustificata e documentata. La stessa deve essere trasmessa per iscritto in via
        elettronica all&rsquo;indirizzo&nbsp;
        <a href="mailto:support@mangiami.ch">support@mangiami.ch</a>.
      </p>
      <p>
        <strong>
          <u>Obblighi del Titolare e limitazioni di responsabilit&agrave;&nbsp;</u>
        </strong>
      </p>
      <p>
        <strong>
          <u>a) Disponibilit&agrave; della Piattaforma&nbsp;</u>
        </strong>
      </p>
      <p>
        Il Titolare mette a disposizione gratuitamente degli utenti la Piattaforma e le risorse
        collegate su base meramente volontaria. L&rsquo;eventuale registrazione non conferisce
        pertanto alcun diritto alla prestazione di beni o servizi, n&eacute; al mantenimento della
        Piattaforma. Il Titolare si riserva il diritto di sospendere, modificare o interrompere, in
        ogni tempo, senza preavviso e in via discrezionale, la disponibilit&agrave; della
        Piattaforma e dei relativi contenuti. Il Titolare si riserva pure il diritto di escludere
        specifici utenti e/o contenuti dalla Piattaforma e dalle risorse ad esso collegate, in
        particolare laddove sussistano indizi di attivit&agrave; o condotte non conformi al presente
        documento.&nbsp;
      </p>
      <p>
        <strong>
          <u>b) Limitazioni di responsabilit&agrave;&nbsp;</u>
        </strong>
      </p>
      <p>
        <u>
          I contenuti degli utenti e dei ristoratori presenti sulla Piattaforma s&rsquo;intendono
          pubblicati senza garanzia di liceit&agrave;, completezza, esattezza o affidabilit&agrave;,
          non essendo tali contenuti sotto il controllo del Titolare o soggetti a verifica
          preventiva. La responsabilit&agrave; per i contenuti ricade esclusivamente su coloro che
          li hanno caricati o condivisi sulla Piattaforma, rispettivamente sui loro autori.
        </u>
      </p>
      <p>
        <u>
          Con l&rsquo;uso della Piattaforma, l&rsquo;utente accetta che il Titolare non assume alcun
          ruolo, come pure alcuna responsabilit&agrave;, in relazione al rapporto contrattuale
          (diretto) tra l&rsquo;utente e il ristoratore rispettivamente tra l&rsquo;utente e il
          servizio di consegna a domicilio.&nbsp;
        </u>
      </p>
      <p>
        <u>
          &Egrave; esclusa qualsiasi responsabilit&agrave; del Titolare in caso di malfunzionamento
          o indisponibilit&agrave; della Piattaforma e delle risorse collegate imputabile ad eventi
          esterni (ad esempio: interruzioni della rete internet, malfunzionamenti dei dispositivi
          dell&rsquo;utente, attacchi informatici, black-out ecc.) oppure nell&rsquo;ambito di
          lavori di ordinaria o straordinaria manutenzione. In caso di urgenza durante periodi di
          temporanea indisponibilit&agrave; della Piattaforma, l&rsquo;utente pu&ograve; contattare
          il Titolare per telefono oppure per e-mail.
        </u>
      </p>
      <p>
        <strong>
          <u>Collegamenti a siti, risorse e servizi online esterni alla Piattaforma</u>
        </strong>
      </p>
      <p>
        La Piattaforma integra collegamenti a siti, risorse e servizi online esterni al proprio
        dominio, ad esempio per acquisire in tempo reale dati e mostrare informazioni (ad esempio,
        immagini, notizie e filmati). Il Titolare non ha alcun controllo su tali siti e risorse, in
        particolare dal profilo della sicurezza, della qualit&agrave; delle informazioni ricevute e
        della protezione dei dati, per cui si declina ogni responsabilit&agrave; per eventuali
        malfunzionamenti, indisponibilit&agrave;, danni o perdite derivanti dall&rsquo;utilizzo di /
        dall&rsquo;affidamento a qualsiasi contenuto, bene e/o servizio proveniente da fonti terze
        oppure disponibile su siti e risorse esterni al dominio mangiami.ch.
      </p>
      <p>
        <strong>
          <u>Condotta degli utenti e dei ristoratori</u>
        </strong>
      </p>
      <p>
        <strong>
          <u>Sicurezza e confidenzialit&agrave; delle credenziali d&rsquo;accesso</u>
        </strong>
      </p>
      <p>
        Il titolare del conto s&rsquo;impegna a mantenere confidenziali e adeguatamente protette
        contro le violazioni della sicurezza le credenziali di accesso, nonch&eacute; a modificare
        la password non appena ricevuta.&nbsp;
      </p>
      <p>
        La password deve adempiere ai seguenti requisiti di sicurezza: minimo 8 caratteri, almeno
        una lettera minuscola, almeno una lettera maiuscola e almeno un carattere speciale. &Egrave;
        responsabilit&agrave; del titolare del conto, ai fini di sicurezza, di modificare
        regolarmente la password di accesso.&nbsp;
      </p>
      <p>
        Il titolare del conto prende atto e accetta che, nei confronti del Titolare e di ogni terzo,
        qualsivoglia attivit&agrave; svolta avvalendosi delle sue credenziali d&rsquo;accesso
        sar&agrave; imputabile al titolare medesimo. Il titolare del conto assume ogni obbligo e/o
        pregiudizio che dovesse derivare dall&rsquo;uso abusivo di tali dati, mantenendo indenni e
        manlevati il Titolare e/o i suoi organi, dirigenti e dipendenti da ogni
        responsabilit&agrave;.
      </p>
      <p>
        <strong>
          <u>Regole generali di comportamento</u>
        </strong>
      </p>
      <p>
        Utenti e ristoratori s&rsquo;impegnano a utilizzare la Piattaforma (e le risorse correlate:
        e-mail, telefono, social media, newsletter ecc.) in maniera lecita, rispettosa dei diritti
        del Titolare e di terzi, nonch&eacute; conforme al presente documento.&nbsp;
      </p>
      <p>In particolare, s&rsquo;impegnano a:</p>
      <ul type="disc">
        <li>
          non utilizzare la Piattaforma (e le risorse correlate) e le informazioni ivi contenute per
          compiere atti illeciti, fraudolenti o lesivi di diritti di terzi;&nbsp;
        </li>
        <li>
          non inserire o condividere, direttamente o indirettamente tramite link, contenuti
          ingannevoli, illeciti, molesti, offensivi, discriminatori, minacciosi, dannosi, volgari o
          altrimenti inappropriati;&nbsp;
        </li>
        <li>
          non inserire o condividere, direttamente o indirettamente tramite link, dati, immagini,
          video, o fotografie relativi a terzi senza averne ottenuto il consenso esplicito;
        </li>
        <li>
          non inserire o condividere, direttamente o indirettamente tramite link, qualsiasi
          contenuto che violi i diritti di propriet&agrave; industriale e intellettuale o le norme
          in vigore sulla protezione dei dati personali;
        </li>
        <li>
          astenersi dalla diffusione e dalla distribuzione a terzi di contenuti della Piattaforma
          senza l&rsquo;autorizzazione scritta preventiva del Titolare;
        </li>
        <li>comunicare dati e informazioni aggiornati e veritieri;</li>
        <li>non utilizzare la Piattaforma a fini pubblicitari;</li>
        <li>
          non interferire o tentare di interferire con l&rsquo;attivit&agrave; della Piattaforma
          oppure ostacolare la fruibilit&agrave; dei servizi da parte di altri utenti;
        </li>
        <li>
          provvedere affinch&eacute; i propri dispositivi informatici siano protetti (ad esempio
          mediante antivirus e firewall) e privi di elementi malevoli (quali malware, virus, cavalli
          di troia ecc.);
        </li>
        <li>
          comunicare dati personali attraverso la piattaforma informatica solo con il consenso
          preventivo ed espresso delle persone interessate, dopo averle informate compiutamente sui
          trattamenti di dati personali connessi.
        </li>
      </ul>
      <p>
        <u>
          Ciascun utente e/o ristoratore s&rsquo;impegna a mantenere indenni e manlevati Hextech e/o
          i suoi organi, dirigenti e dipendenti da qualsiasi danno e/o pregiudizio e/o mancato
          guadagno, sia esso economico o morale, derivante dall&rsquo;uso illecito e/o non conforme
          alle presenti disposizioni della Piattaforma e delle risorse ad essa collegate.
        </u>
      </p>
      <p>
        <strong>
          <u>Esclusione di utenti e/o ristoratori dalla Piattaforma&nbsp;</u>
        </strong>
      </p>
      <p>
        Il Titolare si riserva il diritto, a propria insindacabile discrezione, di restringere,
        parzialmente o completamente, l&rsquo;accesso e/o l&rsquo;utilizzo della Piattaforma&nbsp;e
        delle informazioni e risorse ad essa collegate, come pure di eliminare contenuti senza
        preavviso, in particolare quando sussistono indizi di potenziali attivit&agrave; o
        comportamenti non conformi al presente documento.
      </p>
      <p>&nbsp;</p>
      <li>
        <strong>DIRITTO APPLICABILE E FORO COMPETENTE</strong>
      </li>
      <p>
        <strong>
          Il rapporto giuridico tra l&rsquo;utente e Hextech SA, Sorengo con riferimento
          all&rsquo;accesso e all&rsquo;uso del Sito (e delle risorse collegate) &egrave; regolato
          dal <u>diritto materiale svizzero</u>, escluse le norme di diritto internazionale privato.
        </strong>
      </p>
      <p>
        <strong>
          Le parti scelgono il Giudice ordinario presso la sede di Hextech SA, Sorengo, quale{' '}
          <u>Tribunale esclusivamente competente</u> in caso di controversia scaturente o
          semplicemente connessa con l&rsquo;utilizzo del Sito (e delle risorse correlate),
          riservate eventuali norme di diritto imperativo che impongano un foro differente. Hextech
          SA, Sorengo, si riserva il diritto di adire il Giudice competente presso la sede, la
          succursale o il domicilio dell&rsquo;utente.
        </strong>
      </p>
      <p>
        <u>&nbsp;</u>
      </p>
      <p>
        <u>Data di messa in vigore</u>: 09/07/2019
      </p>
    </div>
  </Layout>
);

export default TCUsers;
